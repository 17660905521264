exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aahaarsearogya-tsx": () => import("./../../../src/pages/aahaarsearogya.tsx" /* webpackChunkName: "component---src-pages-aahaarsearogya-tsx" */),
  "component---src-pages-advocacy-tsx": () => import("./../../../src/pages/advocacy.tsx" /* webpackChunkName: "component---src-pages-advocacy-tsx" */),
  "component---src-pages-certification-tsx": () => import("./../../../src/pages/certification.tsx" /* webpackChunkName: "component---src-pages-certification-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contribute-tsx": () => import("./../../../src/pages/contribute.tsx" /* webpackChunkName: "component---src-pages-contribute-tsx" */),
  "component---src-pages-designyourgarden-tsx": () => import("./../../../src/pages/designyourgarden.tsx" /* webpackChunkName: "component---src-pages-designyourgarden-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-foodheritage-tsx": () => import("./../../../src/pages/foodheritage.tsx" /* webpackChunkName: "component---src-pages-foodheritage-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internship-anna-tsx": () => import("./../../../src/pages/internship/anna.tsx" /* webpackChunkName: "component---src-pages-internship-anna-tsx" */),
  "component---src-pages-internship-field-tsx": () => import("./../../../src/pages/internship/field.tsx" /* webpackChunkName: "component---src-pages-internship-field-tsx" */),
  "component---src-pages-internship-index-tsx": () => import("./../../../src/pages/internship/index.tsx" /* webpackChunkName: "component---src-pages-internship-index-tsx" */),
  "component---src-pages-internship-online-tsx": () => import("./../../../src/pages/internship/online.tsx" /* webpackChunkName: "component---src-pages-internship-online-tsx" */),
  "component---src-pages-internship-swarup-tsx": () => import("./../../../src/pages/internship/swarup.tsx" /* webpackChunkName: "component---src-pages-internship-swarup-tsx" */),
  "component---src-pages-internship-trent-tsx": () => import("./../../../src/pages/internship/trent.tsx" /* webpackChunkName: "component---src-pages-internship-trent-tsx" */),
  "component---src-pages-kudratiaaharparivar-tsx": () => import("./../../../src/pages/kudratiaaharparivar.tsx" /* webpackChunkName: "component---src-pages-kudratiaaharparivar-tsx" */),
  "component---src-pages-kudratikhetigurukul-tsx": () => import("./../../../src/pages/kudratikhetigurukul.tsx" /* webpackChunkName: "component---src-pages-kudratikhetigurukul-tsx" */),
  "component---src-pages-kudratutsav-21-tsx": () => import("./../../../src/pages/kudratutsav21.tsx" /* webpackChunkName: "component---src-pages-kudratutsav-21-tsx" */),
  "component---src-pages-millets-barnyard-millets-tsx": () => import("./../../../src/pages/millets/barnyard-millets.tsx" /* webpackChunkName: "component---src-pages-millets-barnyard-millets-tsx" */),
  "component---src-pages-millets-browntop-millets-tsx": () => import("./../../../src/pages/millets/browntop-millets.tsx" /* webpackChunkName: "component---src-pages-millets-browntop-millets-tsx" */),
  "component---src-pages-millets-finger-millets-tsx": () => import("./../../../src/pages/millets/finger-millets.tsx" /* webpackChunkName: "component---src-pages-millets-finger-millets-tsx" */),
  "component---src-pages-millets-foxtail-millets-tsx": () => import("./../../../src/pages/millets/foxtail-millets.tsx" /* webpackChunkName: "component---src-pages-millets-foxtail-millets-tsx" */),
  "component---src-pages-millets-international-year-of-millets-iyom-2023-tsx": () => import("./../../../src/pages/millets/international-year-of-millets-iyom-2023.tsx" /* webpackChunkName: "component---src-pages-millets-international-year-of-millets-iyom-2023-tsx" */),
  "component---src-pages-millets-kodo-millets-tsx": () => import("./../../../src/pages/millets/kodo-millets.tsx" /* webpackChunkName: "component---src-pages-millets-kodo-millets-tsx" */),
  "component---src-pages-millets-little-millets-tsx": () => import("./../../../src/pages/millets/little-millets.tsx" /* webpackChunkName: "component---src-pages-millets-little-millets-tsx" */),
  "component---src-pages-millets-pearl-millets-tsx": () => import("./../../../src/pages/millets/pearl-millets.tsx" /* webpackChunkName: "component---src-pages-millets-pearl-millets-tsx" */),
  "component---src-pages-millets-proso-millets-tsx": () => import("./../../../src/pages/millets/proso-millets.tsx" /* webpackChunkName: "component---src-pages-millets-proso-millets-tsx" */),
  "component---src-pages-millets-sorghum-millets-tsx": () => import("./../../../src/pages/millets/sorghum-millets.tsx" /* webpackChunkName: "component---src-pages-millets-sorghum-millets-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-organicfarmers-amarjeetsharma-tsx": () => import("./../../../src/pages/organicfarmers/amarjeetsharma.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-amarjeetsharma-tsx" */),
  "component---src-pages-organicfarmers-ashishahuja-tsx": () => import("./../../../src/pages/organicfarmers/ashishahuja.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-ashishahuja-tsx" */),
  "component---src-pages-organicfarmers-avtarsingh-tsx": () => import("./../../../src/pages/organicfarmers/avtarsingh.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-avtarsingh-tsx" */),
  "component---src-pages-organicfarmers-gagansingh-tsx": () => import("./../../../src/pages/organicfarmers/gagansingh.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-gagansingh-tsx" */),
  "component---src-pages-organicfarmers-gauravsahai-tsx": () => import("./../../../src/pages/organicfarmers/gauravsahai.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-gauravsahai-tsx" */),
  "component---src-pages-organicfarmers-gujantmanjeet-tsx": () => import("./../../../src/pages/organicfarmers/gujantmanjeet.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-gujantmanjeet-tsx" */),
  "component---src-pages-organicfarmers-gurmeetbhawalpur-tsx": () => import("./../../../src/pages/organicfarmers/gurmeetbhawalpur.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-gurmeetbhawalpur-tsx" */),
  "component---src-pages-organicfarmers-gurtejsingh-tsx": () => import("./../../../src/pages/organicfarmers/gurtejsingh.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-gurtejsingh-tsx" */),
  "component---src-pages-organicfarmers-index-tsx": () => import("./../../../src/pages/organicfarmers/index.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-index-tsx" */),
  "component---src-pages-organicfarmers-jarnailmajhi-tsx": () => import("./../../../src/pages/organicfarmers/jarnailmajhi.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-jarnailmajhi-tsx" */),
  "component---src-pages-organicfarmers-jaspalkaur-tsx": () => import("./../../../src/pages/organicfarmers/jaspalkaur.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-jaspalkaur-tsx" */),
  "component---src-pages-organicfarmers-jaspreetkaur-tsx": () => import("./../../../src/pages/organicfarmers/jaspreetkaur.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-jaspreetkaur-tsx" */),
  "component---src-pages-organicfarmers-kamaljeethayer-tsx": () => import("./../../../src/pages/organicfarmers/kamaljeethayer.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-kamaljeethayer-tsx" */),
  "component---src-pages-organicfarmers-madanlal-tsx": () => import("./../../../src/pages/organicfarmers/madanlal.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-madanlal-tsx" */),
  "component---src-pages-organicfarmers-nirmalsingh-tsx": () => import("./../../../src/pages/organicfarmers/nirmalsingh.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-nirmalsingh-tsx" */),
  "component---src-pages-organicfarmers-ravdeepsingh-tsx": () => import("./../../../src/pages/organicfarmers/ravdeepsingh.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-ravdeepsingh-tsx" */),
  "component---src-pages-organicfarmers-satindersingh-tsx": () => import("./../../../src/pages/organicfarmers/satindersingh.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-satindersingh-tsx" */),
  "component---src-pages-organicfarmers-shersingh-tsx": () => import("./../../../src/pages/organicfarmers/shersingh.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-shersingh-tsx" */),
  "component---src-pages-organicfarmers-sukhjeetkaur-tsx": () => import("./../../../src/pages/organicfarmers/sukhjeetkaur.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-sukhjeetkaur-tsx" */),
  "component---src-pages-organicfarmers-sukhpreetkaur-tsx": () => import("./../../../src/pages/organicfarmers/sukhpreetkaur.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-sukhpreetkaur-tsx" */),
  "component---src-pages-organicfarmers-swarnsingh-tsx": () => import("./../../../src/pages/organicfarmers/swarnsingh.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-swarnsingh-tsx" */),
  "component---src-pages-organicfarmers-vinodjyani-tsx": () => import("./../../../src/pages/organicfarmers/vinodjyani.tsx" /* webpackChunkName: "component---src-pages-organicfarmers-vinodjyani-tsx" */),
  "component---src-pages-organicfarmersmarket-tsx": () => import("./../../../src/pages/organicfarmersmarket.tsx" /* webpackChunkName: "component---src-pages-organicfarmersmarket-tsx" */),
  "component---src-pages-organicfarming-tsx": () => import("./../../../src/pages/organicfarming.tsx" /* webpackChunkName: "component---src-pages-organicfarming-tsx" */),
  "component---src-pages-organickitchengardening-tsx": () => import("./../../../src/pages/organickitchengardening.tsx" /* webpackChunkName: "component---src-pages-organickitchengardening-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-projectkitchengardens-tsx": () => import("./../../../src/pages/projectkitchengardens.tsx" /* webpackChunkName: "component---src-pages-projectkitchengardens-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-regenerative-agri-tsx": () => import("./../../../src/pages/regenerative-agri.tsx" /* webpackChunkName: "component---src-pages-regenerative-agri-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-seedconservation-tsx": () => import("./../../../src/pages/seedconservation.tsx" /* webpackChunkName: "component---src-pages-seedconservation-tsx" */),
  "component---src-pages-trinjan-tsx": () => import("./../../../src/pages/trinjan.tsx" /* webpackChunkName: "component---src-pages-trinjan-tsx" */),
  "component---src-pages-urbankitchengardening-tsx": () => import("./../../../src/pages/urbankitchengardening.tsx" /* webpackChunkName: "component---src-pages-urbankitchengardening-tsx" */),
  "component---src-pages-volunteer-tsx": () => import("./../../../src/pages/volunteer.tsx" /* webpackChunkName: "component---src-pages-volunteer-tsx" */),
  "component---src-pages-wae-tsx": () => import("./../../../src/pages/wae.tsx" /* webpackChunkName: "component---src-pages-wae-tsx" */)
}

